@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn {
      @apply bg-customBrown rounded-lg border px-8 py-2 border-black uppercase font-semibold shadow-lg hover:bg-customOrange lg:px-16 lg:text-xl;
  }
  .form-error{
      @apply font-semibold text-red-600 text-center text-sm;
  }
  .form-success{
    @apply font-inter text-center text-green-700 text-base;
  }
  .row{
    @apply p-3 text-sm font-inter tracking-wide;
  }
  .hover-zoom{
    @apply transform transition-transform duration-200 hover:scale-105;
  }
  .heading{
    @apply font-inter font-semibold p-4 text-4xl text-center uppercase;
  }
  .hyperlinks{
    @apply underline underline-offset-2 text-blue-700;
  }
}